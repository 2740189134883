import { LocaleStateLocal } from "reducers/locale";

const en: LocaleStateLocal = {
  locale: "en",
  lang: {
    msgUserPwIncorrect: "Username or password incorrect.",
    msgPwMaxFailAttempt:
      "Too many fail login attempts, account has been locked.",
    msgPwMaxFailAttemptReset: "Account unlocked",
    msgNetworkError: "System error",
    msgLoggedOut: " Session expired, please log in again.",
    msgSignInAgain: "Sign in again",
    msgPasswordExpired: "Password has expired, please change password",
    msgDataNotFound: "Record is not found.",
    msgPermissionDenied: "You have no access to the record.",
    msgVersionMismatched:
      "Current record was updated by other users. Please reload.",
    msgDuplicatedBuilding: "Building record already exists.",
    msgAlreadyAssignedClient: "The client has been already claimed",
    msgFloorUnitValidationError:
      "Please input in correct format, e.g. G,1-2,3-10,11",
    msgFloorLevelValidationError:
      "Some of the floors are not covered by levels",
    msgFailedToSendEmailError: "Failed in sending email. Please try again.",
    msgFailedClientSignError: "Failed in signing. Please try again.",
    msgUnsupportedFileFormat: "Unsupported file format: %s",
    msgBuildingAliasAlreadyExists: "Building secondary address already exists.",
    msgFileSizeExceed: "File size limit exceeded",
    msgConfirmDelete: "Are you sure to delete?",
    msgConfirmDone: "Are you sure to mark this message as processed?",
    msgLoading: "Loading",
    msgPullDownToRefresh: "Pull down to refresh",
    msgReleaseToRefresh: "Release to refresh",
    msgWelcome: "Welcome",
    msgMustBeNumber: "Must be number",
    msgMaxLength: "%s words limit",
    u10k: "10K",
    u1M: "M",
    u100M: "100M",
    u1B: "B",
    uFt: "Ft",
    uCurr: "$",
    to: "-",
    infinity: "Infinity",

    dateModified: "Last Modified",
    modifiedBy: "Modified By",
    dateCreated: "Date Created",
    createdBy: "Created By",

    remarks: "Remarks",

    pageTitle: "Sunshine Property",

    //memo
    memos: "Memos",
    content: "Content",
    author: "Author",
    actionAddMemo: "Add Memo",
    msgPleaseInputMemo: "Please input....",

    captionUploadedAt: "uploaded at",
    captionUnsaved: "Unsaved",
    captionName: "Name",
    captionEnglishName: "English Name",
    captionChineseName: "Chinese Name",
    captionDeleted: "Deleted",
    captionAllDistricts: "All Districts",
    captionUnlimited: "Unlimited",
    captionNextPage: "Next",
    captionPrevPage: "Prev",

    actionUpload: "Upload",
    actionConfirmAndUpload: "Confirm & Upload",
    actionDownload: "Download",
    actionNextStep: "Next",
    actionReset: "Reset",
    actionConfirm: "Confirm",
    actionSave: "Save",
    actionSkip: "Skip",
    actionSubmit: "Submit",
    actionAdd: "Add",
    actionDelete: "Delete",
    actionSaveForm: "Save Form",
    actionGenerate: "Generate",
    actionGenerateAndSign: "Sign",
    actionUserProfile: "User Settings",
    actionLogout: "Logout",
    actionYes: "Yes",
    actionNo: "No",
    actionSearch: "Search",
    actionClearSorting: "Clear Sorts",
    actionClose: "Close",
    actionCancel: "Cancel",
    actionGoBack: "Back",
    actionContinue: "Continue",
    actionRemoveSelected: "Remove",
    actionManage: "Manage",
    actionView: "View",
    actionMore: "More",

    captionDistrict: "District",
    captionHKI: "H.K.",
    captionKLN: "Kowloon",
    captionNT: "N.T.",
    captionSearch: "Search...",
    captionSearchHistory: "Search History",
    captionNoSearchResults: "No Search Results",

    // Quick Access Bar Lang
    navDashboard: "Dashboard",
    navPropertyStockSearch: "Property Stock",
    navTransaction: "Transaction",
    navClient: "Client Master",
    navBuilding: "Building Master",
    navMemoEnquiry: "Memo Enquiry",
    navCommissionReport: "Sales & Commission Report",
    navSystemSetting: "System Administration",
    navUserMgt: "User Mgt",
    navSavedSearchCriteria: "Saved Search Criteria",
    navBookmarks: "Bookmarked Properties",
    navActivityAuditing: "Activity Auditing",
    navOptionEditor: "Options Setting",
    navHome: "Home",
    navProperties: "Properties",
    navPropertiesForSale: "Sale",
    navPropertiesForLease: "Lease",
    navPropertiesIndustrial: "Industrial",
    navAgent: "Agents",
    navNotification: "Notifications",
    navEnquiryButton: "Enquiry",
    navEnquiry: "Enquiry",
    navTopAgents: "Top Agents",
    navAbout: "About Us",
    navWebMap: "Map",
    navMarketNews: "Market News",

    // notification
    titleNotifications: "Notifications",
    titleGotoNotificationCenter: "Go to Notification Center",
    captionIsRead: " (Read)",
    captionExpired: "Expired",
    actionDone: "Done",

    //language (SAME in all language file)
    traditionalChinese: "繁體中文",
    simplifiedChinese: "简体中文",
    english: "English",
    traditionalChineseAbbr: "繁",
    simplifiedChineseAbbr: "简",
    englishAbbr: "EN",

    captionPhoto: "Photo",
    captionVideo: "Video",
    captionFloorPlan: "Floor Plan",

    actionPrevPhoto: "Previous",
    actionNextPhoto: "Next",

    captionShare: "Share",
    captionFindHelpByClickingAvatar: "Find Help by Clicking the Avatar",
    captionContactOurTeam: "Contact our Professional Team",
    msgUnsavedChanges: "Changes are not saved. Do you want to leave ?",
  },

  langAgent: {
    actionContactAgent: "Contact",
    actionRating: "Rate",
    actionMoreComment: "More",
    actionLoadMoreAgents: "More",

    captionCustomerRating: "Rating",
    titleAgentComments: "Comments",
    titleAgentDetail: "Details",
    titleOurAgents: "Our Agents",
    titleRatingAndCommentAgent: "Feedback",

    msgNoComment: "(No Comment)",
    msgProvideInfo: "Tell us more and our agents will contact you shortly.",
    msgMaxLength: "50 words limit",
    msgSubmitCommentSuccess: "Your comment is received",
    msgNoRecord: "No Record",

    errPleaseRate: "Please Rate",
    errPleaseComment: "Please Comment",
    errPleaseEnterNoMoreThan50words: "Please limit your comment to 50 words",

    captionComment: "Comment",
    captionSubmitAsAnonymous: "Anonymous",
    captionCustomer: "",
    captionAnonymousCustomer: "Anonymous Customer",
  },
  langRegister: {
    title: "Register",

    captionEmail: "Email",
    captionPassword: "Password",
    captionConfirmPassword: "Confirm Password",
    captionPhoneNumber: "Phone Number",
    captionName: "Name",

    actionRegister: "Register",
    actionLogin: "Login",

    msgRegistrationSuccess: "Registered",
    msgPasswordFailComplexityRequirement:
      "Password must meet complexity requirements",
    msgEmailAlreadyBeenRegistered:
      "Your email has already registered. To reset password, please press Forgot Password at login page.",

    errEmail: "Please enter a valid email",
    errPassword: "Please enter your password",
    errPasswordLeadingTailingSpace:
      "Please remove the leading and tailing space",
    errConfirmPassword: "Please confirm password",
    errPasswordMismatch: "Verifying passsword is not the same",

    //registration more info
    errPhoneNumber: "Please enter your phone number",
    errInvalidPhoneNumber: "Please enter valid phone number",

    //password complexity
    pwMinLength: "The minimum length is ",
    pwAtLeast: "with at least ",
    pwUnit: " ",
    pwLowercaseChar: "lowercase letter(s)",
    pwUppercaseChar: "uppercase letter(s)",
    pwSpecialChar: "special character(s)",
    pwNumber: "number(s)",
  },
  langClientProfile: {
    captionEmail: "Email",
    captionPhoneNumber: "Phone Number",
    captionName: "Name",
    captionSalutation: "Salutation",
    captionPreferenceSetting: "Preferences",
    captionSearchDistrict: "District Subscription",
    captionReceiveEmail: "Promotion Email",
    captionSubscribeNewsletter: "Newsletter",
    captionOldPassword: "Old Password",
    captionNewPassword: "Password",
    captionConfirmPassword: "Confirm Password",

    actionChangePassword: "Change Password",
    actionUploadPhoto: "Upload Photo",

    errOldPassword: "Please enter current password",
    errNewPassword: "Please enter new password",
    errPasswordLeadingTailingSpace:
      "Please remove the leading and tailing space",
    errConfirmNewPassword: "Please confirm new password",
    errPasswordMismatch: "Password mismatch",
    errPhoneNumber: "Please enter a valid contact number",

    msgSaveSuccess: "Saved successfully",
    msgChangePasswordSuccess: "Password changed successfully",
    msgPasswordMismatched: "Incorrect current password",
    msgPasswordFailComplexityRequirement:
      "New password must meet complexity requirements",
    msgPreviousPasswordUsed:
      "New password cannot be the same as the previous password(s)",
    msgPleaseUploadFile: "Please select file",
  },

  langHome: {
    actionBuy: "Buy",
    actionRent: "Rent",
    actionSell: "Sell",
    actionAdvanceSearch: "More",
    actionMore: "More",
    actionMoreProperties: "More Properties",
    actionMoreNews: "More News",
    actionMoreAgents: "More Agents",
    actionSendMessage: "Send Message",

    captionSearchPlaceholder: "District / Building / Street / Property No.",
    captionRange: "$Range",
    captionRoomCount: "Room",
    captionName: "Name",
    captionFirstName: "First Name",
    captionLastName: "Last Name",
    captionPhoneNumber: "Phone",
    captionEmail: "Email",
    captionDistrict: "District",
    captionMessage: "Message",

    titleLatestPropertiesSell: "Latest Properties For Sale",
    titleLatestPropertiesLease: "Latest Properties For Lease",
    titleNews: "Market News",
    titleTopAgents: "Top Agents",
    titleAgentDistrict: "Agent's District",
    titleTopAgentComments: "Customers' Comments",
    titleGetInTouch: "Get In Touch",

    titleLatestNews: "Latest News",
    titleDownloadApp: "App Download",
    titleCustomerService: "Customer Service",
    titleFollowUs: "Follow Us",

    linkAboutUs: "About Us",
    linkFAQ: "FAQ",
    linkPrivacyStatement: "Privacy Statement",
    linkTermsAndCondition: "Terms and Condition",
    linkContactUs: "Contact Us",
  },
  langDistrictSelectDialog: {
    captionDistrict: "District",
    captionHKI: "H.K.",
    captionKLN: "Kowloon",
    captionNT: "N.T.",

    actionOK: "OK",

    selectAll: "Select All",
  },
  langEnquiryDialog: {
    titleEnquiry: "Enquiry",
    titleEnquirySuccess: "Enquiry",

    msgProvideInfo:
      "Please provide the following information, our agent will contact you soon.",
    msgEnquirySuccess:
      "Thank you for your information, our agent will contact you soon.",

    captionEmail: "Email",
    captionContact: "Phone",
    captionName: "Name",
    captionDistrict: "District",

    actionContactMe: "Contact District Agents",
    actionContactMeLeadAgents: "Contact Agents",
    actionSameDistrictAgents: "Select Agent",

    errContact: "Please enter a valid phone number",
    errEmail: "Please enter a valid email",
    errName: "Please enter your name",
    errDistrict: "Please choose district",
    errAtLeastTelOrEmail: "Please enter at least phone or email",
  },
  langLogin: {
    actionLogin: "Login",
    actionLoginOrRegister: "Login/Register",
    actionForgetPassword: "Forget Password",
    actionMemberRegistration: "Member Registration",

    msgFacebookEmailRequired:
      "Please provide your email to finish registration.",
    msgInvalidEmail: "Please input valid email",
    msgSocialLoginCancelled: "Social login cancelled",
    msgLoginSuccess: "Login Success!",
    msgEmailAlreadyBeenRegistered:
      "The email you provided has already been registered, please login using the way when you register.",
    msgForgetPasswordEmailRequired: "Please provide your email for processing.",
    msgForgetPasswordRequestSuccess:
      "Reset password link has been sent to your email address",
    msgResetPasswordTokenExpiry:
      "Request has expired, please make a new request",
    msgPasswordFailComplexityRequirement:
      "The password must meet complexity requirements",
    msgResetPasswordSuccess: "Reset password successfully",
    msgPreviousPasswordUsed:
      "New password cannot be the same as the previous password(s)",
    msgAcceptTermsAndConditionsAndPolicy: "By signing up, you agree to our Terms & Conditions and Privacy Policy.",

    titleFacebookEmail: "Facebook Login",
    titleMemberLogin: "User Login",
    titleResetPassword: "Reset Password",
    actionLoginWithFacebook: "Login with Facebook",
    actionLoginWithGoogle: "Login with Google",
    actionLoginWithApple: "Login with Apple ID",

    errEmail: "Please enter a valid email",
    errInvalidEmail: "Invalid Email",
    errInvalidToken: "Invalid Token",
    errPassword: "Please enter your password",
    errPasswordLeadingTailingSpace:
      "Please remove the leading and tailing space",
    errPasswordMismatch: "Verifying passsword is not the same",

    captionEmail: "Email Address",
    captionPassword: "Password",
    captionRePassword: "Re-enter Password",

    plsRegisterOrLogin: "Please login or register to use more services.",
  },
  langNews: {
    titleNews: "Market News",
    msgSharingTemplate: "I found an interesting news: %s，let's have a look~",
    msgNoRecord: "No Record",
  },
  langNotification: {
    titleNotifications: "Notifications",
    actionYes: "Yes",
    actionNoNeed: "No need",

    msgNoRecord: "No Message",
    actionProcessed: "Processed",
  },
  langProperty: {
    actionReset: "Reset",
    actionSearch: "Search",

    actionBuy: "Buy",
    actionRent: "Rent",

    grossAbbr: "Gross ",
    netAbbr: "Net ",
    rent: "Rent",
    sale: "Sale",

    captionUnpublished: "Unpublished",
    metaAppUnpublishedLabelFontSize: "0.6rem",

    captionPropertyNo: "Property No",
    captionDateModified: "Date Updated",
    captionKeyword: "Keyword",

    captionRent: "Rent",
    captionPrice: "Price",
    captionDistrict: "District",
    captionPropertyUsage: "Usage",
    captionPricePerSize: "Price/ft",
    captionArea: "Size",
    captionAddress: "Address",
    captionMoreOption: "More Options",
    captionAdvancedSearch: "Advanced Search",
    captionBuilding: "Estate/Building",
    captionStreet: "Street",
    captionRooms: "Rooms",
    captionRoom: "Room",
    captionSuite: "Suite",
    captionSuites: "Suites",
    captionOpenType: "Open Type",
    captionBathroom: "Bathroom",
    captionBalcony: "Balcony",
    captionGarden: "Garden",
    captionHelperRoom: "Helper room",
    captionRooftop: "Rooftop",
    captionOccupancyPermitAge: "OP Age (Year)",
    captionHasHelperRoom: "Yes",
    captionNoHelperRoom: "No",
    captionFacing: "Facing",
    captionDeco: "Deco",
    captionView: "View",
    captionOtherFeatures: "Features",
    captionClubHouseFacilities: "Club House Facilities",
    captionOthers: "Others",
    captionPrimarySchoolNet: "School Net (Primary School)",
    captionSecondarySchoolNet: "School Net (Secondary School)",
    captionPrimarySchool: "Primary School",
    captionSecondarySchool: "Secondary School",
    captionSetAsDefaultSearchCriteria: "Set as default search",
    captionFullTransactions: "Transactions",
    captionTransactionDate: "Transaction Date",

    captionPerGross: "Gross",
    captionPerNet: "Net",
    captionYears: "Year",
    captionPricePerFeet: "Price ($) /ft (Net)",

    // Sorting
    captionSorting: "Sorting",
    captionDefaultSorting: "Publish Date",
    captionPriceRentSorting: "Price",
    captionRentSorting: "Rent",
    captionAreaSorting: "Size",
    captionPriceRentPerAreaSorting: "Price/Ft",
    captionRentPerAreaSorting: "Rent/Ft",

    captionHotpick: "Premium",

    titlePropertyInfo: "Property Info",
    titlePropertyFeatures: "Property Features",
    titlePropertyAgents: "Property Agents",
    titleRecentTransactions: "Recent Transaction",
    titleTenYearsPricePerFeet: "10 years @ Price/ft (Net)",
    titlePropertySameDistrict: "Same District",
    titlePropertySameBuilding: "Same Building",
    titleBookmarkList: "Bookmark List",
    captionFeaturesOrFacilities: "Features / Facilities",
    captionOtherInfo: "Other Info",
    captionSchoolNet: "School Net",
    captionMorePropertyInfo: "More Property Info",
    captionOtherPropertiesForSameBuilding: "Same Building",
    captionOtherPropertiesForSameDistrict: "Same District",

    // Sharing
    msgSharingTemplate:
      "I found a nice property from Sunshine Property Consultant Limited：%s，let's have a look~",

    // Sold Leased
    msgSoldClicked: "This property has been sold, please browse the others",
    msgLeasedClicked: "This property has been leased, please browse the others",

    // Save search
    titleSaveSearch: "Saved Search",
    captionLatestSaveSearchShown: "Latest %s saved search will be shown",
    hintForDelSaveSearch: "Swipe left to delete",
    titleCriteriaTitle: "Search Title",
    msgPleaseEnterTitle: "Please enter title",
    msgSaveSearchSuccess: "Search saved successful",
    msgNotExists: "The property is not available",
    errPleaseEnterTitle: "Please enter title",
    captionTitle: "Title",

    actionLoadMore: "Load More",
    actionCenterMap: "Center Map",

    msgNoRecord: "No Record",
    titleNoPropertyFound: "No Listing Found",
    msgNoPropertyFound:
      "Please update your search or get help from our agents.",

    titleDisplayMap: "Map",
    titleDisplayList: "List",
  },
  langSetting: {
    titleSettings: "Settings",
    captionAbout: "About",
    titleAboutCompany: "About Us",
    captionCompanyMission: "Company Mission",
    captionContactUs: "Contact Us",
    captionLanguage: "Language",
    captionMessages: "Messages",
    captionPushNotiEnabled: "Push Notification",
    captionTermsAndConditions: "Terms & Conditions",
    captionPrivacy: "Privacy",
    captionQA: "Q & A",
    captionVersion: "Version",
  },
};

export default en;