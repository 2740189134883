import clsx from 'clsx';
import { useLocalBinder, useReadOnlyBinder } from 'common/hooks/useFormBinder';
import useLocale from 'common/hooks/useLocale';
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import usePageTitle from 'common/hooks/usePageTitle';
import { handlePriceDisplay, handlePriceDisplayUnit, isNonEmpty, isNonEmptyNumberInputRange, multiLang, multiLangNum, priceFromView, rangeFriendlyDisplay } from 'common/utils/utils';
import FormTextField, { FormTextFieldProps } from 'components/form-controls/FormTextField';
import { FormBinder } from 'components/form-controls/IFormBinder';
import DistrictSelectDialog from 'components/overlay/DistrictSelectDialog';
import ModalDialog from 'components/overlay/ModalDialog';
import PopupOverlay from 'components/overlay/PopupOverlay';
import MainLayoutDesktop, { desktopNavigationHeightWithoutRootBar } from 'components/scaffold/MainLayoutDesktop';
import { BaseLayoutProps } from 'components/scaffold/MainLayoutProps';
import React, { forwardRef, Ref, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Spinner from 'react-bootstrap/esm/Spinner';
import Overlay from 'react-bootstrap/Overlay';
import Popover, { PopoverProps } from 'react-bootstrap/Popover';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useLocation } from 'react-router';
import { FormPresenter } from 'reducers/common/form';
import { areaRangeMapping, priceRangeMapping10k, PropertyStockSearchForm, rentRangeMapping } from 'reducers/property-list';
import LargeSlider from './LargeSlider';
import MultiSelectChips from './MultiSelectChips';
import Numbering from './Numbering';
import PropertyMapView, { PropertyMapViewProps } from './PropertyMapView';
import PropertyResultListItemDesktop from './PropertyResultListItemDesktop';
import PropertyResultMapScreenProps from './PropertyResultMapScreenProps';
import { toggleDirection } from './PropertySearchResultScreenMobile';
import PropertySearchResultViewProps, { SortKey } from './PropertySearchResultViewProps';
import SaveSearchDialog from './SaveSearchDialog';
import classes from './SearchScreenDesktop.module.scss';
import { SearchViewMobile } from './SearchScreenLayoutMobile';
import YesNoOptions from './YesNoOptions';
import { saveSearchActions } from 'reducers/save-search';
import { format } from 'util';
import { useDispatch } from 'react-redux';
import { homeActions } from 'reducers/home';


interface SearchViewDesktop {
  searchScreenProps: {
    districtHkiOptions: LocaleOptions, districtKltOptions: LocaleOptions, districtNtOptions: LocaleOptions,
    usageOptions: LocaleOptions, facingOptions: LocaleOptions,
    decoOptions: LocaleOptions,
    viewOptions: LocaleOptions,
    clubHouseFacilitiesOptions: LocaleOptions,
    primarySchoolNetOptions: LocaleOptions,
    secondarySchoolNetOptions: LocaleOptions,
    otherFeatureOptions: LocaleOptions,
    otherOptions: LocaleOptions,

    form: FormPresenter<PropertyStockSearchForm>;
    onSearchClick?: AnyFn;
    onRequestMore?: AnyFn;
    onResetClick?: AnyFn;

    // Save Search
    bindSearchTitle: FormBinder<string>;
    onSaveClick?: AnyFn;
    onSaveSubmit?: AnyFn;
    saveDialogOpen: boolean;
    onHideSaveDialog?: AnyFn;
  };
  ordering: {
    direction: PropertySearchResultViewProps['direction'],
    sortKey: PropertySearchResultViewProps['sortKey'],
    onSortSelect: PropertySearchResultViewProps['onSortSelect'],
  };
  mapResultProps: PropertyResultMapScreenProps;
  mapViewProps: PropertyMapViewProps;
  layoutProps: BaseLayoutProps;
  onEnquiryClick?: AnyFn;
}

export const SearchCriteriaDesktop = forwardRef((props: FormTextFieldProps & { onClick?: AnyFn }, ref: Ref<HTMLDivElement>) => {
  const { onClick, label, ...rest } = props;
  return <div className={classes['search-criteria']}>
    <div className={classes['label']}>{label}</div>
    <div ref={ref} className={classes['field']} onClick={onClick}>
      <FormTextField {...rest} hideClearButton readOnly label={''} />
    </div>
  </div>
})

export const CriteriaPopoverDesktop = (props: React.PropsWithChildren<PopoverProps & { title?: string, id: string, }>) => {
  const { title, id, ...rest } = props;
  return <Popover {...rest} id={id}>
    {props.title ? <Popover.Title as="h3">{props.title}</Popover.Title> : null}
    <Popover.Content>
      {props.children}
    </Popover.Content>
  </Popover>
};

export default function SearchScreenDesktop(props: SearchViewDesktop) {
  const { langProperty, langHome, lang, locale } = useLocale();
  const [ districtOpen, setDistrictOpen ] = useState(false);
  const [ sortingOpen, setSortingOpen ] = useState(false);
  const [currentSortingDirection, setcurrentSortingDirection] = useState('DESC');
  const [ usageOpen, setUsageOpen ] = useState(false);
  const [ priceOpen, setPriceOpen ] = useState(false);
  const [ areaOpen, setAreaOpen ] = useState(false);

  const sortingBtnRef = useRef<HTMLDivElement>(null);
  const usageRef = useRef<HTMLDivElement>(null);
  const priceRef = useRef<HTMLDivElement>(null);
  const areaRef = useRef<HTMLDivElement>(null);

  usePageTitle(lang.navProperties);

  const {
    form,
    districtHkiOptions, districtKltOptions, districtNtOptions,
    usageOptions, 
    otherFeatureOptions,
    facingOptions,
    decoOptions,
    viewOptions,
    clubHouseFacilitiesOptions,
    primarySchoolNetOptions, otherOptions,
    secondarySchoolNetOptions,
    onSaveClick, onSearchClick, onResetClick,

    bindSearchTitle,
    onSaveSubmit,
    saveDialogOpen,
    onHideSaveDialog,
  } = props.searchScreenProps;

  const {
    direction, sortKey,
    onSortSelect,
  } = props.ordering;

  const dispatch = useDispatch();
  const maxTitleLength = 16777215;
  

  const allDistricts = useMemo(() => ({
    ...districtHkiOptions, ...districtKltOptions, ...districtNtOptions,
  }), [ districtHkiOptions, districtKltOptions, districtNtOptions ]);
  const districtDisplay = useReadOnlyBinder(form.field('district').value?.map(n => allDistricts[n]).join(' / ') ?? '');

  

  // const dummyFacingBind = useDummyBinder<string[]>([]);
  // const dummyDecoBind = useDummyBinder<string[]>([]);
  // const dummyRoom1 = useDummyBinder(1);
  const moreOptionDialogShow = useLocalBinder(false);

  const sortingItem = (display: string, sort: SortKey) => {
    return <div key={String(sort)} className={classes['sorting-option']} onClick={() => {
      const newDirection = sortKey === sort ? toggleDirection(direction) : direction;
      onSortSelect?.(sort, newDirection);
      setSortingOpen(false);
      setcurrentSortingDirection(newDirection);
    }}>
      {sortKey === sort && direction === 'DESC' ? <i className="fas fa-sort-amount-down"></i> : null}
      &nbsp;{display}&nbsp;
      {sortKey === sort && direction === 'ASC' ? <i className="fas fa-sort-amount-down-alt"></i> : null}
    </div>
  };

  const sortingPopover = <Popover id={'Popover-Sorting'}>
    <Popover.Content style={{ padding: '0', minWidth: '8.25rem' }}>
      {sortingItem(langProperty.captionDefaultSorting, null)}
      {sortingItem(form.field('buyOrRent').value === 'BUY' ? langProperty.captionPriceRentSorting : langProperty.captionRentSorting, 'priceOrRent')}
      {sortingItem(langProperty.captionAreaSorting, 'net')}
      {sortingItem(form.field('buyOrRent').value === 'BUY' ? langProperty.captionPriceRentPerAreaSorting : langProperty.captionRentPerAreaSorting, 'priceOrRentPerNet')}
    </Popover.Content>
  </Popover>;


  const usageDisplay = useReadOnlyBinder(form.field('usage').value?.map(n => usageOptions[n]).join(' / ') ?? '');
  const usagePopover = <Popover id={'Popover-PropertyUsage'}>
    <Popover.Content>
      <MultiSelectChips label={langProperty.captionPropertyUsage} options={usageOptions} bind={form.field('usage')}/>
    </Popover.Content>
  </Popover>;

  const areaDisplay = useReadOnlyBinder(form.field('net').value 
    ?.map(n => n === Infinity ? lang.captionUnlimited : n).join(' - ') + ' ' + lang.uFt
  );
  const areaPopover = <Popover id={'Popover-Area'} style={{ maxWidth: `calc(300rem / 16)` }}>
    <Popover.Content>
      <LargeSlider label={langProperty.captionArea} unit={lang.uFt} min={0} max={8000} step={100} valueMapping={areaRangeMapping} bind={form.field('net')}/>
    </Popover.Content>
  </Popover>;

  const priceDisplay = useReadOnlyBinder(
    form.field('priceDisplay').value ? rangeFriendlyDisplay(form.field('priceDisplay').value!, locale, num => `$${handlePriceDisplay(priceFromView(num, locale), locale)}${handlePriceDisplayUnit(priceFromView(num, locale), locale, lang)}`) : lang.captionUnlimited,
  );
  const rentDisplay = useReadOnlyBinder(
    form.field('rent').value ? rangeFriendlyDisplay(form.field('rent').value!, locale, num => `$${num}`) : lang.captionUnlimited,
  );
  const priceMapping = locale === 'en' ? priceRangeMapping10k.map(n => n / 100) : priceRangeMapping10k;
  const pricePopover = <Popover id={'Popover-Price'} style={{ maxWidth: `calc(300rem / 16)` }}>
    <Popover.Content>
      {form.field('buyOrRent').value === 'BUY' && <LargeSlider label={langProperty.captionPrice} unit={multiLang(locale, lang.u10k, lang.u1M)!} min={0} max={multiLangNum(locale, 8000, 8000/100)} step={multiLangNum(locale, 100, 100/100)} valueMapping={priceMapping} bind={form.field('priceDisplay')}/>}
      {form.field('buyOrRent').value === 'RENT' && <LargeSlider label={langProperty.captionRent} unit={''} min={0} max={8000} step={100} valueMapping={rentRangeMapping} bind={form.field('rent')}/>}
    </Popover.Content>
  </Popover>;

  // Calculate Content Height
  const searchBarRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => setSearchBarHeight(searchBarRef.current?.offsetHeight ?? 150), [ searchBarRef.current?.offsetHeight ?? 150 ]);
  const [ searchBarHeight, setSearchBarHeight ] = useState(150);
  const screenHeight = useMobileLayoutHeight(true);
  const contentHeight = `calc(${screenHeight} - ${desktopNavigationHeightWithoutRootBar} - ${searchBarHeight}px)`;

  // Small Screen Handling
  const isSmallScreen = useMediaQuery({ maxWidth: 812 });
  const [ showMobileSearchView, setShowMobileSearchView ] = useState(false);
  // const mobileSearchViewBtnRef = useRef<HTMLButtonElement>(null);
  // Extra Small Screen Handling
  const isExtraSmallScreen = useMediaQuery({ maxWidth: 685 });
  const [ showLeftList, setShowLeftList ] = useState(false);
  // Handle openMoreDialog state (dirty)
  const location = useLocation<{ openMoreDialog?: boolean }>();
  const history = useHistory();
  const openMoreDialog = location.state?.openMoreDialog;
  useEffect(() => {
    if (openMoreDialog) {
      if (isSmallScreen) {
        setShowMobileSearchView(true);
      } else {
        moreOptionDialogShow.change(true);
      }

      history.replace('/map-list', { openMoreDialog: false });
    }
  }, [ openMoreDialog, history ]);

  const existingMoreOptionSelections = isNonEmpty(form.field('buildingName').value) || 
    isNonEmpty(form.field('street').value) ||
    isNonEmpty(form.field('buyOrRent').value) 
      && form.field('buyOrRent').value === 'BUY' 
      && isNonEmptyNumberInputRange(form.field('priceDisplay').value?.[0], form.field('priceDisplay').value?.[1]) ||
    isNonEmpty(form.field('buyOrRent').value) 
      && form.field('buyOrRent').value === 'RENT' 
      && isNonEmptyNumberInputRange(form.field('rent').value?.[0], form.field('rent').value?.[1]) ||
    isNonEmptyNumberInputRange(form.field('net').value?.[0], form.field('net').value?.[1]) ||
    isNonEmpty(form.field('roomEnabled').value) ||
    isNonEmpty(form.field('suiteEnabled').value) ||
    isNonEmpty(form.field('bathroomEnabled').value) ||
    isNonEmpty(form.field('balconyEnabled').value) ||
    isNonEmpty(form.field('helperRoomEnabled').value) ||
    isNonEmpty(form.field('facing').value) ||
    isNonEmpty(form.field('deco').value) ||
    isNonEmpty(form.field('view').value) ||
    isNonEmpty(form.field('otherFeatures').value) ||
    isNonEmpty(form.field('clubHouseFacilities').value) ||
    isNonEmpty(form.field('others').value) ||
    isNonEmpty(form.field('primarySchoolNet').value) ||
    isNonEmpty(form.field('secondarySchoolNet').value) ||
    isNonEmpty(form.field('district').value) ||
    isNonEmpty(form.field('usage').value) ||
    false
  ;

  const existingOptionSelections = isNonEmpty(form.field('district').value) ||
    isNonEmpty(form.field('usage').value) ||
    form.field('buyOrRent').value === 'BUY' && !(form.field('priceDisplay').value?.[0] === 0 && form.field('priceDisplay').value?.[1] === Infinity) ||
    form.field('buyOrRent').value === 'RENT' && !(form.field('rent').value?.[0] === 0 && form.field('rent').value?.[1] === Infinity) ||
    !(form.field('net').value?.[0] === 0 && form.field('net').value?.[1] === Infinity) ||
    isNonEmpty(form.field('buildingName').value) || 
    isNonEmpty(form.field('street').value) || 
    isNonEmpty(form.field('roomEnabled').value) ||
    isNonEmpty(form.field('suiteEnabled').value) ||
    isNonEmpty(form.field('bathroomEnabled').value) ||
    isNonEmpty(form.field('balconyEnabled').value) ||
    isNonEmpty(form.field('helperRoomEnabled').value) ||
    isNonEmpty(form.field('facing').value) ||
    isNonEmpty(form.field('deco').value) ||
    isNonEmpty(form.field('view').value) ||
    isNonEmpty(form.field('clubHouseFacilities').value) ||
    isNonEmpty(form.field('primarySchoolNet').value) ||
    isNonEmpty(form.field('secondarySchoolNet').value)
  ;

  return <MainLayoutDesktop {...props.layoutProps} hideFooter flexBody navigationRootBarBehavior="show-on-hover">
    <DistrictSelectDialog districts={form.field('district')} open={districtOpen} onHide={() => setDistrictOpen(false)}
      districtHkiOptions={districtHkiOptions} districtKltOptions={districtKltOptions} districtNtOptions={districtNtOptions}
    />

    <SaveSearchDialog bindTitle={bindSearchTitle} onSave={onSaveSubmit} show={saveDialogOpen} onHide={onHideSaveDialog} />

    <div className={classes['root']}>
      {/* Search Bar*/}
      <div className={classes['search-bar']} ref={searchBarRef} style={{ paddingTop: isSmallScreen ? '0rem' : undefined }}>
        {/* Sorting */}
        <Overlay rootClose placement="bottom-start" show={sortingOpen} onHide={() => setSortingOpen(false)} target={sortingBtnRef.current}>{sortingPopover}</Overlay>
        <div className={classes['sorting-btn']} ref={sortingBtnRef} onClick={() => setSortingOpen(true)}>
          <i className="fas fa-sort-amount-down" style={{ display: currentSortingDirection === 'ASC' ? 'none' : undefined }}></i>
          <i className="fas fa-sort-amount-down-alt" style={{ display: currentSortingDirection === 'DESC' ? 'none' : undefined }}></i>
          {/* <img src="images/sort-float-btn.png" /> */}
        </div>

        {!isSmallScreen ? <>
          {/* Buy/Rent Toggle */}
          <div className="btn-group" role="group">
            <button type="button" className={clsx('btn', form.field('buyOrRent').value === 'BUY' ? 'btn-secondary' : 'btn-outline-secondary')} 
                onClick={() => {form.field('buyOrRent').change('BUY');dispatch(homeActions.updateSearchType('BUY'));}}>
              {langProperty.actionBuy}
            </button>
            <button type="button" className={clsx('btn', form.field('buyOrRent').value === 'RENT' ? 'btn-secondary' : 'btn-outline-secondary')} 
                onClick={() => {form.field('buyOrRent').change('RENT');dispatch(homeActions.updateSearchType('RENT'));}}>
              {langProperty.actionRent}
            </button>
          </div>

          {/* District */}
          {/* <SearchCriteriaDesktop
            label={langProperty.captionDistrict}
            onClick={() => setDistrictOpen(true)}
            bind={districtDisplay}
          /> */}

          {/* Property Usage */}
          {/* <Overlay rootClose placement="bottom-start" show={usageOpen} onHide={() => setUsageOpen(false)} target={usageRef.current}>{usagePopover}</Overlay>
          <SearchCriteriaDesktop ref={usageRef}
            label={langProperty.captionPropertyUsage}
            onClick={() => setUsageOpen(true)}
            bind={usageDisplay}
          /> */}

          <div className={classes['search-criteria']} style={{ flexGrow: 2 }}>
            <div className={classes['label']}>{langProperty.captionKeyword}&nbsp;</div>
            <div className={classes['field']}>
              <FormTextField
                overrideClassName={classes['keyword-field']}
                prepend={<i className={'fas fa-search'} />} prependClassName={classes['keyword-field-icon']}
                label={langHome.captionSearchPlaceholder}
                bind={form.field('freeTextSearch')}
                onSubmit={onSearchClick} name="SearchScreenDesktop-keyword-field"
              />
            </div>
          </div>

          {/* Price/Rent */}
          <Overlay rootClose placement="bottom-start" show={priceOpen} onHide={() => setPriceOpen(false)} target={priceRef.current}>{pricePopover}</Overlay>
          <SearchCriteriaDesktop ref={priceRef}
            label={langProperty.captionPrice}
            onClick={() => setPriceOpen(true)}
            bind={form.field('buyOrRent').value === 'BUY' ? priceDisplay : rentDisplay}
          />

          {/* Area */}
          <Overlay rootClose placement="bottom-start" show={areaOpen} onHide={() => setAreaOpen(false)} target={areaRef.current}>{areaPopover}</Overlay>
          <SearchCriteriaDesktop ref={areaRef}
            label={langProperty.captionArea}
            onClick={() => setAreaOpen(true)}
            bind={areaDisplay}
          />

          {/* Advanced Search Button */}
          <Button variant="outline-pas-orange" onClick={() => moreOptionDialogShow.change(true)}
            className={`btn ${classes['more-option-btn']}`}
            style={{ borderRadius: '0.25rem', width: 'calc(38rem / 16)', fontSize: '1.2rem', position: 'relative' }}>
            <i className="fas fa-ellipsis-h"></i>
            { existingMoreOptionSelections && <div className={classes['red-reminder']} /> }
          </Button>

          {/* Search Button */}
          <Button variant="pas-orange" onClick={onSearchClick}>
            {lang.actionSearch}
          </Button>

          <Button variant="outline-pas-orange" onClick={onResetClick}>
            {langProperty.actionReset}
          </Button>

          {/* Save Search Button */}
          <Button variant="outline-pas-orange" onClick={onSaveClick}>
            {langProperty.titleSaveSearch}
          </Button>
        </> : null}

        {isSmallScreen ? <>
          {/* {isExtraSmallScreen ? <Button variant="pas-gray" onClick={() => setShowLeftList(!showLeftList)}>{showLeftList ? langProperty.titleDisplayList : langProperty.titleDisplayMap}</Button> : null} */}
          {/* Grow */}
          <div className={classes['search-criteria']} style={{ flexGrow: 2 }}>
            <div className={classes['label']}>&nbsp;</div>
            <FormTextField
              readOnly
              overrideClassName={classes['keyword-field']}
              prepend={<i className={'fas fa-search'} />} prependClassName={classes['keyword-field-icon']}
              label={langHome.captionSearchPlaceholder}
              onClick={() => setShowMobileSearchView(true)}
            />
          </div>
          

          {/* Save Search Button */}
          {/* <Button variant="outline-pas-orange" onClick={onSaveClick}>
            {langProperty.titleSaveSearch}
          </Button> */}
          {/* Search View Mobile Button */}
          {/* <Button variant="outline-pas-orange" onClick={() => setShowMobileSearchView(true)} ref={mobileSearchViewBtnRef}
            className={`btn ${classes['more-option-btn']}`}
            style={{ borderRadius: '0.25rem', width: 'calc(38rem / 16)', fontSize: '1.2rem', position: 'relative' }}>
            <i className="fas fa-ellipsis-h"></i>
            { existingOptionSelections && <div className={classes['red-reminder']} /> }
          </Button> */}
          {/* <Button variant="outline-pas-orange" onClick={() => moreOptionDialogShow.change(true)}
            className={`btn ${classes['more-option-btn']}`}
            style={{ borderRadius: '0.25rem', width: 'calc(38rem / 16)', fontSize: '1.2rem', position: 'relative' }}>
            <i className="fas fa-ellipsis-h"></i>
            { existingMoreOptionSelections && <div className={classes['red-reminder']} /> }
          </Button> */}
          {/* Search Button */}
          {/* <Button variant="pas-orange" onClick={onSearchClick}>
            {lang.actionSearch}
          </Button> */}

          {/* <Button variant="outline-pas-orange" onClick={onResetClick}>
            {langProperty.actionReset}
          </Button> */}
          {/* <Button variant="pas-orange" onClick={() => setShowMobileSearchView(true)} ref={mobileSearchViewBtnRef}>{'搜寻条件 ▼'}</Button> */}
          
          {/* Search View Mobile (popover) */}
          {/* <Overlay rootClose placement="bottom-start" show={showMobileSearchView} onHide={() => setShowMobileSearchView(false)} target={mobileSearchViewBtnRef.current}>
            <Popover id={'MobileSearchView'} className={classes['mobile-search-view-popover']}>
              <Popover.Content>
                <SearchViewMobile {...props.searchScreenProps} hideActionButtons titleOverride={'搜尋條件'} />
              </Popover.Content>
            </Popover>
          </Overlay> */}
        </> : null}
      </div>

      {/* Search View Mobile (overlay list view) */}
      <div style={{ position: 'absolute', pointerEvents: showMobileSearchView && isSmallScreen ? undefined : 'none', top: 0, left: 0, width: 'calc(360rem / 16)', height: contentHeight }}>
        <div style={{ width: 'calc(360rem / 16)', height: contentHeight }}>
          <PopupOverlay show={showMobileSearchView && isSmallScreen}>
            <div className="bg-white" style={{ height: '100%' }}>
              <SearchViewMobile
                {...props.searchScreenProps}
                onSaveSubmit={() => {
                  if (!isNonEmpty(bindSearchTitle.value)) {
                    dispatch(saveSearchActions.updateSaveSearchTitleError(langProperty.errPleaseEnterTitle));
                  }
                  else if (bindSearchTitle.value!.length > maxTitleLength) {
                    dispatch(saveSearchActions.updateSaveSearchTitleError(format(lang.msgMaxLength, maxTitleLength)));
                  }
                  else {
                    dispatch(saveSearchActions.addSearchCriteria());
                    dispatch(saveSearchActions.closeSaveSearchDialog());
                  }
                }}
                onBackClick={() => setShowMobileSearchView(false)}
                onSearchClick={() => {
                  props.searchScreenProps.onSearchClick?.();
                  setShowMobileSearchView(false);
                  setShowLeftList(false);
                }}
              />
            </div>
          </PopupOverlay>
        </div>
      </div>

      {/* Content Body */}
      <div className={classes["content-body"]} style={{ height: contentHeight }}>
      {/* Search Result Listing (Shows when left list is NOT shown) */}
      {(!isExtraSmallScreen || !showLeftList) && (
        <div className={classes["listing-container"]}>
        <div style={{width: "100%", display: "flex", justifyContent: "center", alignItems: "center",}}>
            {props.mapResultProps.filteredLoading && <Spinner animation="grow" />}
        </div>
        <div className={clsx(classes["content-grid"], "container-fluid")}>
          <div className="row">
            {/* No Property Found Message */}
            {!props.mapResultProps?.properties?.length && !props.mapResultProps.filteredLoading && (
                <div className={classes["not-found-view"]}>
                  <div className={classes["title"]}>{langProperty.titleNoPropertyFound}</div>
                  <div className={classes["hints"]}>{langProperty.msgNoPropertyFound}</div>
                  <div className={classes["action"]}>
                    <Button variant="pas-orange" onClick={props.onEnquiryClick}>{lang.navEnquiry}</Button>
                  </div>
                </div>
            )}

            {/* Property List */}
            {props.mapResultProps?.properties?.map((property) => (
              <div className={clsx(classes["grid"], "col-lg-6 col-md-12")} key={property.id}>
                <PropertyResultListItemDesktop {...property} />
              </div>
            ))}

            {/* Load More Button */}
            {props.mapResultProps.filteredHasMore && (
              <Container className={classes["more-btn-container"]}>
                <div className={classes["more-btn"]} 
                    onClick={props.searchScreenProps.onRequestMore}>{langProperty.actionLoadMore}<i className="fas fa-arrow-down" />
                </div>
              </Container>
            )}
          </div>
        </div>
        </div>
      )}

        {/* Map (Shows when left list IS shown) */}
        {(!isExtraSmallScreen || showLeftList) && (
          <div className={classes["map-container"]}>
            <PropertyMapView
              {...props.mapViewProps}
              onClusterClick={(pidList) => {
                setShowLeftList(false);
                props.mapViewProps.onClusterClick?.(pidList);
              }}
            />
          </div>
        )}
      </div>
    </div>

    <ModalDialog size="lg" backdrop title={langProperty.captionMoreOption} show={moreOptionDialogShow.value} onHide={() => moreOptionDialogShow.change(false)}
      headerChildren={(header) => <>
        <button className={'btn btn-outline-pas-blue'} onClick={onResetClick}>{lang.actionReset}</button>
        {header}
        <div style={{ display: 'flex', flexDirection: 'row-reverse', width: 'calc(63.73rem/16)' }}><button style={{ backgroundColor: 'transparent', border: 'none' }}
          onClick={() => {
            onSearchClick?.();
            moreOptionDialogShow.change(false);
          }}
        >
          <i className="fas fa-search" />
        </button></div>
      </>}
    >
      <>
        <div style={{ display: 'flex', flexWrap: 'wrap', padding: '2rem 1rem calc(8rem/16) 1rem', alignItems: 'center', }}>
          <div style={{ marginRight: '0rem', paddingLeft: '0', paddingRight: 'calc(8rem/16)' }} className={'col-md-6'}>
            <div className={classes['caption']}>{langProperty.captionDistrict}</div>
            <div onClick={() => setDistrictOpen(true)}>
              <FormTextField
                hideClearButton readOnly
                bind={districtDisplay}
                label={langProperty.captionDistrict}
              />
            </div>
          </div>
          <div style={{ marginRight: '0rem', paddingRight: '0', paddingLeft: 'calc(8rem/16)' }} className={'col-md-6'}>
            <div className={classes['caption']}>{langProperty.captionPropertyUsage}</div>
            <Overlay rootClose placement="bottom-start" show={usageOpen} onHide={() => setUsageOpen(false)} target={usageRef.current}>{usagePopover}</Overlay>
            <div ref={usageRef} onClick={() => setUsageOpen(true)}>
              <FormTextField 
                hideClearButton readOnly
                bind={usageDisplay}
                label={langProperty.captionPropertyUsage}
              />
            </div>
          </div>
          
          <div style={{ marginRight: '0rem', paddingLeft: '0', paddingRight: 'calc(8rem/16)' }} className={'col-md-6'}>
            <div className={classes['caption']}>{langProperty.captionBuilding}</div>
            <FormTextField bind={form.field('buildingName')} label={langProperty.captionBuilding} />
          </div>
          <div style={{ marginRight: '0rem', paddingRight: '0', paddingLeft: 'calc(8rem/16)' }} className={'col-md-6'}>
            <div className={classes['caption']}>{langProperty.captionStreet}</div>
            <FormTextField bind={form.field('street')} label={langProperty.captionStreet} />
          </div>
          <div style={{ marginRight: '0', paddingLeft: '0', paddingRight: 'calc(8rem/16)' }} className={'col-md-6'}>
            <div className={classes['part-container']}>
              {form.field('buyOrRent').value === 'BUY' && <LargeSlider label={langProperty.captionPrice} unit={multiLang(locale, lang.u10k, lang.u1M)!} min={0} max={multiLangNum(locale, 8000, 8000/100)} step={multiLangNum(locale, 100, 100/100)} valueMapping={priceMapping} bind={form.field('priceDisplay')}/>}
              {form.field('buyOrRent').value === 'RENT' && <LargeSlider label={langProperty.captionRent} unit={''} min={0} max={100000} step={100} valueMapping={rentRangeMapping} bind={form.field('rent')}/>}
            </div>
          </div>
          <div style={{ marginRight: '0', paddingRight: '0', paddingLeft: 'calc(8rem/16)' }} className={'col-md-6'}>
            <div className={classes['part-container']}>
              <LargeSlider label={langProperty.captionArea} unit={lang.uFt} min={0} max={8000} step={100} valueMapping={areaRangeMapping} bind={form.field('net')}/>
            </div>
          </div>
          <div style={{ marginRight: '1rem' }}>
            <Numbering label={langProperty.captionRoom} min={0} max={10} bind={form.field('room')} bindEnabled={form.field('roomEnabled')} vertical={false} />
          </div>
          <div style={{ marginRight: '1rem' }}>
            <Numbering label={langProperty.captionSuite} min={0} max={10} bind={form.field('suite')} bindEnabled={form.field('suiteEnabled')} vertical={false} />
          </div>
          <div style={{ marginRight: '1rem' }}>
            <Numbering label={langProperty.captionBathroom} min={0} max={10} bind={form.field('bathroom')} bindEnabled={form.field('bathroomEnabled')} vertical={false} />
          </div>
          <div style={{ marginRight: '1rem' }}>
            <Numbering label={langProperty.captionBalcony} min={0} max={10} bind={form.field('balcony')} bindEnabled={form.field('balconyEnabled')} vertical={false} />
          </div>
          <div style={{ marginRight: '1rem', display: 'flex' }}>
            <YesNoOptions yesLabel={lang.actionYes} noLabel={lang.actionNo} label={langProperty.captionHelperRoom} bind={form.field('hasHelperRoom')} bindEnabled={form.field('helperRoomEnabled')} />
          </div>
        </div>
        
        <Container fluid style={{ padding: '0 1rem 2rem 1rem' }}>
          <Row>
            <Col lg={12}>
              <Row className={classes['feature-row']} style={{ paddingTop: '0.55rem', paddingBottom: '0.55rem', borderTop: 'var(--pas-border-color) 1px solid' }}><Col>
                <MultiSelectChips collapsible initialExpand label={langProperty.captionFacing} options={facingOptions} bind={form.field('facing')}/>
              </Col></Row>

              <Row className={classes['feature-row']} style={{ paddingTop: '0.55rem', paddingBottom: '0.55rem', borderTop: 'var(--pas-border-color) 1px solid' }}><Col>
                <MultiSelectChips collapsible initialExpand label={langProperty.captionDeco} options={decoOptions} bind={form.field('deco')}/>
              </Col></Row>

              <Row className={classes['feature-row']} style={{ paddingTop: '0.55rem', paddingBottom: '0.55rem', borderTop: 'var(--pas-border-color) 1px solid' }}><Col>
                <MultiSelectChips collapsible initialExpand label={langProperty.captionView} options={viewOptions} bind={form.field('view')}/>
              </Col></Row>

              <Row className={classes['feature-row']} style={{ paddingTop: '0.55rem', paddingBottom: '0.55rem', borderTop: 'var(--pas-border-color) 1px solid' }}><Col>
                <MultiSelectChips collapsible initialExpand label={langProperty.captionOtherFeatures} options={otherFeatureOptions} bind={form.field('otherFeatures')}/>
              </Col></Row>
              
              <Row className={classes['feature-row']} style={{ paddingTop: '0.55rem', paddingBottom: '0.55rem', borderTop: 'var(--pas-border-color) 1px solid' }}><Col>
                <MultiSelectChips collapsible label={langProperty.captionClubHouseFacilities} options={clubHouseFacilitiesOptions} bind={form.field('clubHouseFacilities')}/>
              </Col></Row>

              <Row className={classes['feature-row']} style={{ paddingTop: '0.55rem', paddingBottom: '0.55rem', borderTop: 'var(--pas-border-color) 1px solid' }}><Col>
                <MultiSelectChips collapsible label={langProperty.captionOthers} options={otherOptions} bind={form.field('others')}/>
              </Col></Row>

              <Row className={classes['feature-row']} style={{ paddingTop: '0.55rem', paddingBottom: '0.55rem', borderTop: 'var(--pas-border-color) 1px solid' }}><Col>
                <MultiSelectChips collapsible label={langProperty.captionPrimarySchoolNet} options={primarySchoolNetOptions} bind={form.field('primarySchoolNet')}/>
              </Col></Row>

              <Row className={classes['feature-row']} style={{ paddingTop: '0.55rem', paddingBottom: '0.55rem', borderTop: 'var(--pas-border-color) 1px solid' }}><Col>
                <MultiSelectChips collapsible label={langProperty.captionSecondarySchoolNet} options={secondarySchoolNetOptions} bind={form.field('secondarySchoolNet')}/>
              </Col></Row>
            </Col>
          </Row>
        </Container>
      </>

    </ModalDialog>
  </MainLayoutDesktop>
}