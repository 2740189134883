import { SignInWithApple, SignInWithAppleResponse } from "@capacitor-community/apple-sign-in";
import { FacebookLogin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
import { Capacitor } from '@capacitor/core';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { User } from "@codetrix-studio/capacitor-google-auth";
import { ApiError, ApiResult, BASE_URL } from 'api';
import { customerApi } from 'api/customerApi';
import loginApi from 'api/loginApi';
import CALoginStateDTO from 'common/dto/CALoginStateDTO';
import { selectLocale } from 'common/hooks/useLocale';
import { checkValidEmail, isMobileUI } from 'common/utils/utils';
import { IRootState } from "reducers";
import { facebookEmailActions } from 'reducers/facebook-email';
import { layoutActions } from 'reducers/layout';
import { localeActions } from "reducers/locale";
import { loginActions, loginSelectors } from 'reducers/login';
import { loginFormActions } from 'reducers/login-form';
import { notificationActions } from 'reducers/notification';
import { registerDialogActions } from "reducers/register-dialog";
import { registerFormActions } from "reducers/register-form";
import { call, put, select, take, takeLatest, takeLeading } from 'redux-saga/effects';
import { PASSagaContext } from 'sagas';
import { store } from "store";
import { ActionType, getType } from 'typesafe-actions';
import { apiTaskWrapper } from './saga-commons';
import { jumpers, sagaJump } from "common/theme/jumper";


// const { FacebookLogin, GoogleAuth } = Plugins;

export default [watchNormalLoginRequested, watchGoogleLoginRequested, watchFacebookLoginRequested, watchRegisterRequested, watchLogoutRequested, watchAppleLoginRequested];

export function* watchNormalLoginRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(loginActions.start),
    apiTaskWrapper(normalLogin),
    context
  )
}

export function* watchGoogleLoginRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(loginActions.startGoogleLogin),
    apiTaskWrapper(googleLogin),
    context,
  );
}

export function* watchAppleLoginRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(loginActions.startAppleLogin),
    apiTaskWrapper(appleLogin),
    context,
  );
}

export function* watchFacebookLoginRequested(context: PASSagaContext) {
  yield takeLatest(
    getType(loginActions.startFacebookLogin),
    apiTaskWrapper(facebookLogin, { noMask: true }),
    context,
  );
}

export function* watchLogoutRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(loginActions.logout),
    apiTaskWrapper(logout),
    context
  );
}

function* commonSuccessHandling(context: PASSagaContext, data: CALoginStateDTO | absent) {
  const location = (context.browserHistory.location.state as any)?.fromPath ?? '/home';
  if(data?.language){
    yield put(localeActions.switchLocale(data?.language));
  }
  const { langLogin } = yield select(selectLocale());
  yield put(layoutActions.alert(langLogin.msgLoginSuccess, 'success'));
  yield put(loginActions.success(data!));
  if (isMobileUI()) {
    yield call(context.browserHistory.replace as any, location);
  }
  yield put(loginFormActions.closeDialog());
  yield put(loginFormActions.reset());
  yield put(notificationActions._onNotificationReceiveEvent());

  const PROFILE: String = yield select((state: IRootState) => state.systemSettings?.BuildInfo?.ENV?.PROFILE) ?? null;

  window.SCBeacon?.trackEvent("Client contact event", {
    xclientId: (PROFILE ? `pas-client-${PROFILE.toLowerCase()}-` : "") + data?.id,
    xfirstName: data?.name,
    xlastName: '',
    xemail: data?.email,
    xlanguage: data?.language
  });
}

export function* watchRegisterRequested(context: PASSagaContext) {
  yield takeLeading(
    getType(loginActions.register),
    apiTaskWrapper(register),
    context
  )
}

export function* normalLogin(_context: PASSagaContext, action: ActionType<typeof loginActions['start']>) {
  const { username, password } = action.payload;
  const deviceId: string = yield select(loginSelectors.selectDeviceId());
  const { data, error }: ApiResult<CALoginStateDTO> = yield call(loginApi.login, {
    username, password, deviceId,
  });
  const { lang } = yield select(selectLocale());

  if (error) {
    if (ApiError.isErrorCode(error, 'ERR_NOT_AUTHORIZED')) {
      yield put(layoutActions.alert(lang.msgUserPwIncorrect, 'error'));
      yield put(loginActions.fail());
      return;
    } else {
      throw ApiError.of(error);
    }
  }

  yield* commonSuccessHandling(_context, data);

}

export function* googleLogin(_context: PASSagaContext) {
  const { langLogin } = yield select(selectLocale());
  const deviceId: string = yield select(loginSelectors.selectDeviceId());
  let googleUser: User;
  try {
    googleUser = yield call(() => GoogleAuth.signIn());
  } catch (e) {
    console.debug(e);
    yield put(layoutActions.alert(langLogin.msgSocialLoginCancelled, 'info'))
    return;
  }
  console.debug('Google Login Success', googleUser);
  const { data, error }: ApiResult<CALoginStateDTO> = yield call(loginApi.googleLogin, {
    idToken: googleUser.authentication.idToken,
    deviceId,
  });

  if (error) {
    throw ApiError.of(error);
  }

  yield* commonSuccessHandling(_context, data);
}

export function* appleLogin(_context: PASSagaContext) {
  const { langLogin } = yield select(selectLocale());
  const deviceId: string = yield select(loginSelectors.selectDeviceId());
  let idToken: string = '';
  if (Capacitor.isNativePlatform()) {
    let appleUser: SignInWithAppleResponse;
    try {
      appleUser = yield call(() => SignInWithApple.authorize({
        clientId: 'com.propertymavin.client',
        scopes: 'name email',
        redirectURI: `${BASE_URL}/ca/apple-signin-return`,
        state: 'init',
        nonce: 'property mavin nonce',
      }));
    } catch (e) {
      console.debug(e);
      yield put(layoutActions.alert(langLogin.msgSocialLoginCancelled, 'info'));
      return;
    }
    console.debug('Apple Login Success', appleUser);
    idToken = appleUser.response.identityToken;
  } else {
    try {
      const data: {
        authorization: {
          id_token: string,
        }
      } = yield call(() => window.AppleID?.auth.signIn());
      idToken = (data).authorization.id_token;
    } catch (e) {
      console.debug(e);
      yield put(layoutActions.alert(langLogin.msgSocialLoginCancelled, 'info'));
      return;
    }
  }

  const { data, error }: ApiResult<CALoginStateDTO> = yield call(loginApi.appleLogin, {
    idToken: idToken ?? '',
    deviceId,
  });

  if (error) {
    throw ApiError.of(error);
  }

  yield* commonSuccessHandling(_context, data);
}

const FACEBOOK_PERMISSIONS = ["email",
  "user_friends"];

export function* facebookLogin(_context: PASSagaContext) {
  const deviceId: string = yield select(loginSelectors.selectDeviceId());
  const fbUser: FacebookLoginResponse = yield call(() => FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS }));
  const { langLogin } = yield select(selectLocale());
  console.debug('Facebook Login Success', fbUser);

  if (fbUser === undefined) {
    console.debug('Facebook user is undefined', fbUser);
    yield put(layoutActions.alert(langLogin.msgSocialLoginCancelled, 'info'))
    return;
  }


  if (!fbUser.accessToken) {
    console.debug('Facebook user no accessToken', fbUser);
    yield put(layoutActions.alert(langLogin.msgSocialLoginCancelled, 'info'))
    return;

  }

  let { data, error }: ApiResult<CALoginStateDTO> = yield call(loginApi.facebookLogin, {
    accessToken: fbUser.accessToken!.token,
    deviceId,
  });

  if (error) {

    if (ApiError.isErrorCode(error, 'ERR_EMAIL_INPUT_REQUIRED')) {
      yield put(facebookEmailActions.openFacebookEmailDialog());
      while (true) {
        const emailAction: ActionType<typeof facebookEmailActions['confirmFacebookAdHocEmail']> = yield take(getType(facebookEmailActions.confirmFacebookAdHocEmail));

        const email = emailAction.payload.email;
        if (!checkValidEmail(email)) {
          yield put(facebookEmailActions.updateEmailError(langLogin.msgInvalidEmail));
          continue;
        }

        const retryResult: ApiResult<CALoginStateDTO> = yield call(loginApi.facebookLogin, {
          accessToken: fbUser.accessToken!.token,
          deviceId,
          email: emailAction.payload.email,
          code: emailAction.payload.code,
          ticket: emailAction.payload.ticket,
        });

        if (!retryResult.error) {
          yield put(facebookEmailActions.closeFacebookEmailDialog());
          break;
        } else {
          if (ApiError.isErrorCode(retryResult.error, 'ERR_EMAIL_ALREADY_BEEN_REGISTERED')) {
            yield put(facebookEmailActions.updateEmailError(langLogin.msgEmailAlreadyBeenRegistered));
          } else {
            throw ApiError.of(error);
          }
        }
      }
    } else {
      throw ApiError.of(error);
    }

  }

  yield* commonSuccessHandling(_context, data);
}

export function* logout(context: PASSagaContext) {
  const { token } = yield select((state: IRootState) => state.login);
  const deviceId: string = yield select(loginSelectors.selectDeviceId());
  yield call(loginApi.logout, token, deviceId);
  yield put(loginActions._loggedOut());
  if (isMobileUI()) {
    yield sagaJump(jumpers.toLogin(), context.browserHistory);
    window.location.reload();
  } else {
    yield call(context.browserHistory.replace, "/home");
    window.location.reload();
  }
}


export function* register(_context: PASSagaContext, action: ActionType<typeof loginActions['register']>) {
  const { type, email, password, phoneNumber, name } = action.payload;
  const { data, error }: ApiResult<CALoginStateDTO> = yield call(loginApi.register, {
    type, email, password, phoneNumber, name
  });
  const { lang, langRegister } = yield select(selectLocale());
  const deviceId: string = yield select(loginSelectors.selectDeviceId());
  if (error) {
    if (error === 'ERR_PW_FAIL_COMPLEXITY_REQUIREMENT') {
      yield put(layoutActions.alert(langRegister.msgPasswordFailComplexityRequirement, 'error'));
      yield put(registerFormActions.showRequirement());
    } else if (ApiError.isErrorCode(error, 'ERR_EMAIL_ALREADY_BEEN_REGISTERED')) {
      yield put(layoutActions.alert(langRegister.msgEmailAlreadyBeenRegistered, 'error'));
    } else {
      yield put(registerFormActions.hideRequirement());
      throw ApiError.of(error);
    }
    yield put(registerFormActions.clearInput());
  } else {
    yield put(layoutActions.alert(langRegister.msgRegistrationSuccess, 'success'));
    // yield call(_context.browserHistory.replace as any, '/login');
    const { data, error }: ApiResult<CALoginStateDTO> = yield call(loginApi.login, {
      username: email, password, deviceId: deviceId
    });

    if (error) {
      if (ApiError.isErrorCode(error, 'ERR_NOT_AUTHORIZED')) {
        yield put(layoutActions.alert(lang.msgUserPwIncorrect, 'error'));
        return;
      } else {
        throw ApiError.of(error);
      }
    } else {
      yield put(registerFormActions.reset());
      yield put(registerFormActions.hideRequirement());

      const { langLogin, locale } = yield select(selectLocale());
      const { open } = yield select((state: IRootState) => state.registerDialog);
      yield put(layoutActions.alert(langLogin.msgLoginSuccess, 'success'));
      yield put(loginActions.success(data!));

      const { token } = yield select((state: IRootState) => state.login);
      yield call(customerApi.switchCustomerLocale, locale, token);

      if (isMobileUI()) {
        yield call(_context.browserHistory.replace as any, 'register-more');
      } else if (open) {
        yield put(registerDialogActions.setCurrentStep(1));
      }
    }

  }

}

export const renewAndReloadToken = (token: string) => {
  if (token) {
    try {
      // loginApi.renew(token!).then(res => (
      // store.dispatch({ type: 'Login.Success', payload: { ...res.data! } })));
      store.dispatch({ type: 'Layout.DataReloadRequested' });

    }
    catch (e) {
      store.dispatch({ type: 'Login.LogoutRequested' });
      return { error: String(e) };

    }
  }
}