import { PUBLIC_URL } from 'api';
import newsApi from 'api/newsApi';
import clsx from 'clsx';
import CANewsListItemDTO from 'common/dto/CANewsListItemDTO';
import 'common/general-imports';
import useLocale from 'common/hooks/useLocale';
import { useMobileLayoutHeight } from 'common/hooks/useMobileLayoutHeight';
import { isNonEmpty, multiLang } from 'common/utils/utils';
import DesktopNavigationBar from 'components/navigation/DesktopNavigationBar';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducers';
import classes from './MainLayoutDesktop.module.scss';
import MainLayoutProps, { NavigationKeys } from './MainLayoutProps';

const DEFAULT_LOGIN_STATE = {
	loggedIn: false,
	email: '',
	name: '',
	photoFilename: '',
};

const EMPTY_OBJECT = {};

export const desktopNavigationHeight = 'calc(125rem / 16)';
export const desktopNavigationRootBarHeight = 'calc(54rem / 16)';
export const desktopNavigationHeightWithoutRootBar = `calc(${desktopNavigationHeight} - ${desktopNavigationRootBarHeight})`;

export default function MainLayoutDesktop(props: MainLayoutProps) {

	const { lang, langHome, langSetting, locale } = useLocale();

	const { loggedIn, email, name, photoFilename } = props.loginState ?? DEFAULT_LOGIN_STATE;

	const { criteria } = useSelector((state: IRootState) => state.news ?? EMPTY_OBJECT) ?? EMPTY_OBJECT;
	const DISPLAY_DATE_FORMAT = useSelector((state: IRootState) => state.systemSettings?.System?.SYSTEM_PARAM?.DISPLAY_DATE_FORMAT);

	const { COMPANY_FACEBOOK, COMPANY_INSTAGRAM, COMPANY_TWITTER, GOOGLE_PLAY_DOWNLOAD_LINK, APPS_STORE_DOWNLOAD_LINK } = useSelector((state: IRootState) => state.systemSettings.ClientApp.FOOTER);
	const minDesktopHeight = useMobileLayoutHeight(true);

	const navBtns = [
		{ 
			key: '/home',
			caption: lang.navHome,
		},
		// { 
		// 	key: '/properties',
		// 	caption: lang.navPropertiesForSale,
		// 	navParam: '?buyOrRent=BUY',
		// 	onClick: () => {
		// 		dispatch(propertyListActions.edit('buyOrRent', 'BUY'));
		// 		onNavigate?.('/properties');
		// 	}
		// },
		// { 
		// 	key: '/properties',
		// 	caption: lang.navPropertiesForLease,
		// 	navParam: '?buyOrRent=RENT',
		// 	onClick: () => {
		// 		dispatch(propertyListActions.edit('buyOrRent', 'RENT'));
		// 		onNavigate?.('/properties');
		// 	}
		// },
		{ 
			key: '/map-list',
			caption: lang.navProperties
		},
		// { 
		// 	key: '/bookmarks',
		// 	caption: langProperty.titleBookmarkList,
		// },
		// {
		// 	key: '/news',
		// 	caption: lang.navMarketNews
		// },
		{ 
			key: '/about',
			caption: lang.navAbout
		},
		{ 
			key: 'EnquiryDialog.Open',
			caption: lang.navEnquiry,
		},
		// { 
		// 	key: '/agents',
		// 	caption: lang.navTopAgents,
		// },
		
		// { 
		// 	key: !loggedIn ? 'LoginForm.OpenDialog' : '/customer-profile',
		// 	caption: !loggedIn ? langLogin.actionLogin : <div style={{ display: 'flex', alignItems: 'center' }}>
		// 		<ProfileImage src={photoFilename ? BASE_URL + '/files/' + photoFilename : ''} width={'1.5rem'} />
		// 		<div>&nbsp; {name || email}</div>
		// 	</div>
		// },
	]

	const { activeNavigation, onNavigate } = props;

	const [ footerNews, setFooterNews ] = useState<CANewsListItemDTO[]>([]);
	
	const history = useHistory();
	
	useEffect(() => {
		newsApi.getFooterList({
			...criteria, 
			displayLanguage: multiLang(locale, 'Traditional Chinese', 'English', 'Simplified Chinese')
		}, '').then(({ data, error }) => {
			if (error) {
				return;
			} else if (data) {
				setFooterNews(data ?? []);
			}
		});
	}, []);

  return <div className={clsx(classes['main-layout-desktop'])} style={{ minHeight: minDesktopHeight }}>
		<div className={classes['header']}>
			<DesktopNavigationBar
				activeKey={activeNavigation} 
				navigationButtons={navBtns} 
				onClickNavigationButton={(key) => onNavigate?.(key as NavigationKeys)} 
				onClickLoginOrRegisterButton={() => onNavigate?.('LoginForm.OpenDialog' as NavigationKeys)}
				onClickCustomerProfile={() => onNavigate?.('/customer-profile' as NavigationKeys)}
				loggedIn={loggedIn}
				email={email}
				name={name}
				photoFilename={photoFilename}
				rootBarBehavior={props.navigationRootBarBehavior}
				tabBarIconExtraClasses={props.tabBarIconExtraClasses}
			/>
			{/* <img src={demoHeader} style={{ width: '100%', position: 'fixed', zIndex: 900,  }} /> */}

		</div>
		<div className={clsx(classes['body'], props.flexBody && classes['flex-body'])} style={{
			paddingTop: props.navigationRootBarBehavior === 'show-on-hover' ? desktopNavigationHeightWithoutRootBar : desktopNavigationHeight
		}}>
			{props.children}
		</div>
		<footer className={"footer" + " " + classes['footer']} style={{ display: props.hideFooter ? 'none' : undefined }}>
			{/* <div className="container"> */}
				<div className={clsx([classes['footer-main'], 'container'])}>
					<div className="row" style={{ marginLeft: 0, marginRight: 0}}>
						{/* <div className="col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3">
							<img src="images/logo-max.png"  style={{ height: 'calc(40rem / 16)' }}/>
						</div> */}
						{/* <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
							<div className={classes['footer-col']}>
								<div className={classes['footer-col-title']}>
									{langHome.titleLatestNews}
								</div>
								<div className="row">
									<div className="col-12">
										<a className={classes['footer-link']}>消息1</a>
									</div>
									<div className="col-12">
										<a className={classes['footer-link']}>消息2</a>
									</div>
									<div className="col-12">
										<a className={classes['footer-link']}>消息3</a>
									</div>
								</div>
							</div>
						</div> */}

						<div className="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
							<div className={classes['footer-col']}>
								<div className={classes['footer-col-title']}>
									{/* {langHome.titleFollowUs} */}
									<img src="images/logo-small.png"  style={{ height: '6.5rem'}}/>
								</div>
								<div className={classes['footer-contact']}>
									<ul>
								  		<li><a href={COMPANY_FACEBOOK} target="_blank" className={classes['icon-facebook-f']} style={{ display: (COMPANY_FACEBOOK === "N/A" || !isNonEmpty(COMPANY_FACEBOOK)) ? 'none' : undefined }}><i className="fab fa-facebook-f" /></a></li>
								  		<li><a href={COMPANY_TWITTER} target="_blank" className={classes['icon-twitter']} style={{ display: (COMPANY_TWITTER === "N/A" || !isNonEmpty(COMPANY_TWITTER)) ? 'none' : undefined }}><i className="fab fa-twitter" /></a></li>
								  		<li><a href={COMPANY_INSTAGRAM} target="_blank" className={classes['icon-instagram']} style={{ display: (COMPANY_INSTAGRAM === "N/A" || !isNonEmpty(COMPANY_INSTAGRAM)) ? 'none' : undefined }}><i className="fab fa-instagram" /></a></li>
										{/* <li><a href="#"><i className="fab fa-goolge-plus-g"/></a></li> */}
										{/* <li><a href="#"><img src="images/fb.png"/></a></li>
										<li><a href="#"><img src="images/ig.png"/></a></li> */}
										{/* <li><a href="#" className="icon fa fa-twitter icon-xxs icon-circle"></a></li>
										<li><a href="#" className="icon fa fa-google-plus icon-xxs icon-circle"></a></li>
										<li><a href="#" className="icon fa fa-linkedin icon-xxs icon-circle"></a></li> */}
									</ul>
								</div>
							</div>
						</div>

						<div className="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
							<div className={classes['footer-col']}>
						  		<div className={classes['footer-col-title']} style={{ display: (GOOGLE_PLAY_DOWNLOAD_LINK === "N/A" || !isNonEmpty(GOOGLE_PLAY_DOWNLOAD_LINK)) && (APPS_STORE_DOWNLOAD_LINK === "N/A" || !isNonEmpty(APPS_STORE_DOWNLOAD_LINK)) ? 'none' : undefined }}>
									{langHome.titleDownloadApp}
								</div>
								<div className="row">
									<div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" + " " + classes['footer-download']}>
								  		<a href={GOOGLE_PLAY_DOWNLOAD_LINK} target="_blank" style={{ display: (GOOGLE_PLAY_DOWNLOAD_LINK === "N/A" || !isNonEmpty(GOOGLE_PLAY_DOWNLOAD_LINK)) ? 'none' : undefined }}>
											<img src="images/android.png" />
										</a>
									</div>
									<div className={"col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" + " " + classes['footer-download']}>
								  		<a href={APPS_STORE_DOWNLOAD_LINK} target="_blank" style={{ display: (APPS_STORE_DOWNLOAD_LINK === "N/A" || !isNonEmpty(APPS_STORE_DOWNLOAD_LINK)) ? 'none' : undefined }}>
											<img src="images/ios.png" />
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4">
							<div className={classes['footer-col']}>
								<div className={classes['footer-col-title']}>
									{lang.navWebMap}
								</div>
								<div className="row">
									<ul className={classes['footer-ul']}>
										{
											navBtns.filter(item => item.key !== 'EnquiryDialog.Open').map(item => <li key={item.key}>
												<a className={classes['footer-link']} href={`${PUBLIC_URL}${item.key}`}>{item.caption}</a>
											</li>)
										}
										<li><a className={classes['footer-link']} href={`${PUBLIC_URL}/settings/T&C`}>
											{langSetting.captionTermsAndConditions}
										</a></li>
										<li><a className={classes['footer-link']} href={`${PUBLIC_URL}/settings/privacy`}>
											{langSetting.captionPrivacy}
										</a></li>
									</ul>
								</div>
							</div>
						</div>

						{/* <div className="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
							<div className={classes['footer-col']}>
								<div className={classes['footer-col-title']}>
									{lang.navMarketNews}
								</div>
								{
									footerNews.map(n => <FooterMarketNewsItem
										id={n.id}
										date={n.publishDate ? moment(n.publishDate).format(DISPLAY_DATE_FORMAT) : ''}
										title={n.heading}
										onClick={n.internal ? () => history.push(`/news/${n.id}`) : (n.link ? () => window.open(n.link) : undefined)}
									/>)
								}
								<Button variant="pas-light-orange" 
									className={classes['more-btn']}
									onClick={() => history.push(`/news`)}
								>
									{langHome.actionMoreNews}
								</Button>
							</div>
						</div> */}

						
						{/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div style={{ backgroundColor: '#242424'}}>
								<p style={{ color: '#FFFFFF',  fontSize: 'calc(12rem / 16)',  margin: '14px 0px' }} >
									© Property Mavin. 2020. All Right Reserved.
								</p>
							</div>
						</div> */}
					</div>
				</div>
				{/* <div className={classes['footer-brand']}>
					<img src="images/logo-max.png"/>
				</div> */}
			
				<div className={classes['footer-copyright']}>
					<p>
						© Sunshine Property Consultant Limited. {new Date().getFullYear()}. All Rights Reserved.
					</p>
				</div>
				
			{/* </div> */}
		</footer>
	</div>
}
