import { LocaleStateLocal } from "reducers/locale";

const zhCN: LocaleStateLocal = {
  locale: "zh_CN",
  lang: {
    msgUserPwIncorrect: "用户名或密码错误",
    msgPwMaxFailAttempt: "登入错误次数过多，帐户已锁定",
    msgPwMaxFailAttemptReset: "帐户已解除锁定",
    msgNetworkError: "系统错误",
    msgLoggedOut: "使用期限己过，请重新登入",
    msgSignInAgain: "重新登入",
    msgPasswordExpired: "密码已过期, 请修改密码",
    msgDataNotFound: "找不到相关资料",
    msgPermissionDenied: "您无权访问相关资料",
    msgVersionMismatched: "另一位操作者修改了当前资料, 请重新加载",
    msgDuplicatedBuilding: "大厦档案已存在",
    msgAlreadyAssignedClient: "客户已被认领",
    msgFloorUnitValidationError: "请输入正确的格式，例：G,1-2,3-10,11",
    msgFloorLevelValidationError: "部分楼层没有被低中高层涵盖",
    msgFailedToSendEmailError: "发送失败, 请重新尝试。",
    msgFailedClientSignError: "签署失败, 请重新尝试。",
    msgUnsupportedFileFormat: "不支援此文件格式: %s",
    msgBuildingAliasAlreadyExists: "大厦副地址已存在",
    msgFileSizeExceed: "此文件大小超过限制",
    msgConfirmDelete: "确认删除?",
    msgConfirmDone: "确认已处理?",
    msgLoading: "载入中",
    msgPullDownToRefresh: "向下拉动以重载",
    msgReleaseToRefresh: "放开以重载",
    msgWelcome: "欢迎",
    msgMustBeNumber: "须为数字",
    msgMaxLength: "最多%s字",
    u10k: "万",
    u1M: "百万",
    u100M: "亿",
    u1B: "十亿",
    uFt: "呎",
    uCurr: "$",
    to: "至",
    infinity: "不限",

    dateModified: "最后更新",
    modifiedBy: "最后更新帐户",
    dateCreated: "新增日期",
    createdBy: "创建帐户",
    remarks: "备注",

    pageTitle: "阳光物业",

    memos: "备忘录",
    content: "内容",
    author: "作者",
    actionAddMemo: "新增备忘",
    msgPleaseInputMemo: "请输入备忘....",

    captionUploadedAt: "上载于",
    captionUnsaved: "尚未储存",
    captionName: "姓名",
    captionEnglishName: "英文姓名",
    captionChineseName: "中文姓名",
    captionDeleted: "已删除",
    captionAllDistricts: "全部地区",
    captionUnlimited: "不限",
    captionNextPage: "上一页",
    captionPrevPage: "下一页",

    actionUpload: "上载",
    actionConfirmAndUpload: "确认并上载",
    actionDownload: "下载",
    actionNextStep: "下一步",
    actionReset: "重设",
    actionConfirm: "确认",
    actionSave: "储存",
    actionSkip: "略过",
    actionSubmit: "提交",
    actionAdd: "新增",
    actionDelete: "删除",
    actionSaveForm: "储存表格",
    actionGenerate: "生成PDF档案",
    actionGenerateAndSign: "签署",
    actionUserProfile: "帐户设定",
    actionLogout: "登出",
    actionYes: "是",
    actionNo: "否",
    actionSearch: "搜寻",
    actionClearSorting: "清除排列",
    actionClose: "关闭",
    actionCancel: "取消",
    actionGoBack: "返回",
    actionContinue: "继续",
    actionRemoveSelected: "删除所选",
    actionManage: "管理",
    actionView: "检视",
    actionMore: "更多",

    captionDistrict: "地区",
    captionHKI: "香港岛",
    captionKLN: "九龙",
    captionNT: "新界",
    captionSearch: "搜寻...",
    captionSearchHistory: "搜寻纪录",
    captionNoSearchResults: "没有搜寻结果",

    // Quick Access Bar Lang
    navDashboard: "仪表板",
    navPropertyStockSearch: "楼盘搜寻",
    navTransaction: "成交纪录",
    navClient: "客户档案",
    navBuilding: "大厦档案",
    navMemoEnquiry: "备忘查阅",
    navSystemSetting: "系统管理",
    navCommissionReport: "销售及分佣报告",
    navUserMgt: "帐户管理",
    navBookmarks: "心水楼盘",
    navSavedSearchCriteria: "已储存搜寻",
    navActivityAuditing: "使用记录",
    navOptionEditor: "选项管理",
    navHome: "首页",
    navProperties: "物业资料",
    navPropertiesForSale: "买盘",
    navPropertiesForLease: "租盘",
    navPropertiesIndustrial: "工商铺",
    navAgent: "代理",
    navNotification: "通知",
    navEnquiryButton: "联络",
    navEnquiry: "联络代理",
    navTopAgents: "星级代理",
    navAbout: "关于我们",
    navWebMap: "网站地图",
    navMarketNews: "市场资讯",

    // notification
    titleNotifications: "通知",
    titleGotoNotificationCenter: "前往通知中心",
    captionIsRead: " (已读)",
    captionExpired: "过期",
    actionDone: "完成",

    //language (SAME in all language file)
    traditionalChinese: "繁体中文",
    simplifiedChinese: "简体中文",
    english: "English",
    traditionalChineseAbbr: "繁",
    simplifiedChineseAbbr: "简",
    englishAbbr: "EN",

    captionPhoto: "相片",
    captionVideo: "影片",
    captionFloorPlan: "平面图",

    actionPrevPhoto: "上一张",
    actionNextPhoto: "下一张",

    captionShare: "分享",
    captionFindHelpByClickingAvatar: "按头像找代理帮手",
    captionContactOurTeam: "联络我们的专业代理团队",
    msgUnsavedChanges: "未储存本页修改, 确定要离开 ?",
  },

  langAgent: {
    actionContactAgent: "联络代理",
    actionRating: "给评级",
    actionMoreComment: "更多评语",
    actionLoadMoreAgents: "载入更多",

    captionCustomerRating: "客户评级",
    titleAgentComments: "客户评语",
    titleAgentDetail: "代理详情",
    titleOurAgents: "我们的代理",
    titleRatingAndCommentAgent: "评级代理",

    msgNoComment: "(暂无评语)",
    msgProvideInfo: "请提供以下资料，代理会很快与你联络。",
    msgMaxLength: "最多50字",
    msgSubmitCommentSuccess: "已经提交评语",
    msgNoRecord: "暂无纪录",

    errPleaseRate: "请评级",
    errPleaseComment: "请评语",
    errPleaseEnterNoMoreThan50words: "请提交最多50字",

    captionComment: "评语",
    captionSubmitAsAnonymous: "以匿名身份提交",
    captionCustomer: "客户",
    captionAnonymousCustomer: "匿名客户",
  },
  langRegister: {
    title: "会员注册",

    captionEmail: "电邮地址",
    captionPassword: "密码",
    captionConfirmPassword: "确认密码",
    captionPhoneNumber: "联络电话",
    captionName: "姓名",

    actionRegister: "注册",
    actionLogin: "会员登入",

    msgRegistrationSuccess: "注册成功",
    msgPasswordFailComplexityRequirement: "密码必须符合设定要求",
    msgEmailAlreadyBeenRegistered:
      "您输入的电邮已被注册，若想重设现有用户之密码，请于登录页面使用忘记密码功能",

    errEmail: "请输入正确的电邮地址",
    errPassword: "请输入密码",
    errPasswordLeadingTailingSpace: "请移除头尾空格",
    errConfirmPassword: "请确认密码",
    errPasswordMismatch: "输入的密码不一致",

    //registration more info
    errPhoneNumber: "请输入电话号码",
    errInvalidPhoneNumber: "请输入正确的电话号码",

    //password complexity
    pwMinLength: "密码长度最少为",
    pwAtLeast: "包含最少",
    pwUnit: "个",
    pwLowercaseChar: "小写字母",
    pwUppercaseChar: "大写字母",
    pwSpecialChar: "特殊符号",
    pwNumber: "数字",
  },
  langClientProfile: {
    captionEmail: "电邮地址",
    captionPhoneNumber: "联络电话",
    captionName: "姓名",
    captionSalutation: "称呼",
    captionPreferenceSetting: "喜好设定",
    captionSearchDistrict: "地区订阅",
    captionReceiveEmail: "推广电邮",
    captionSubscribeNewsletter: "客户通讯",
    captionOldPassword: "旧密码",
    captionNewPassword: "密码",
    captionConfirmPassword: "确认密码",

    actionChangePassword: "更改密码",
    actionUploadPhoto: "上载照片",

    errOldPassword: "请输入现有密码",
    errNewPassword: "请输入新密码",
    errPasswordLeadingTailingSpace: "请移除头尾空格",
    errConfirmNewPassword: "请确认密码",
    errPasswordMismatch: "输入的密码不一致",
    errPhoneNumber: "请输入正确的电话号码",

    msgSaveSuccess: "储存成功",
    msgChangePasswordSuccess: "更改密码成功",
    msgPasswordMismatched: "现有密码不正确",
    msgPasswordFailComplexityRequirement: "新密码必须符合设定要求",
    msgPreviousPasswordUsed: "新密码不可与旧密码相同",
    msgPleaseUploadFile: "请选择档案",
  },

  langHome: {
    actionBuy: "买楼",
    actionRent: "租楼",
    actionSell: "放盘",
    actionAdvanceSearch: "进阶搜寻",
    actionMore: "更多",
    actionMoreProperties: "更多楼盘",
    actionMoreNews: "更多资讯",
    actionMoreAgents: "更多代理",
    actionSendMessage: "送出信息",

    captionSearchPlaceholder: "地区 / 大厦名称 / 街道 / 楼盘编号",
    captionRange: "$范围",
    captionRoomCount: "房间",
    captionName: "姓名",
    captionFirstName: "名",
    captionLastName: "姓",
    captionPhoneNumber: "联络电话",
    captionEmail: "电邮地址",
    captionDistrict: "地区",
    captionMessage: "信息",

    titleLatestPropertiesSell: "最新卖盘",
    titleLatestPropertiesLease: "最新租盘",
    titleNews: "市场动态",
    titleTopAgents: "星级代理",
    titleAgentDistrict: "代理地区",
    titleTopAgentComments: "客户评价",
    titleGetInTouch: "联系我们",

    titleLatestNews: "最新消息",
    titleDownloadApp: "App 下载",
    titleCustomerService: "客户服务",
    titleFollowUs: "联络我们",

    linkAboutUs: "关于我们",
    linkFAQ: "常见问题",
    linkPrivacyStatement: "私隐政策",
    linkTermsAndCondition: "条款细则",
    linkContactUs: "联络我们",
  },
  langDistrictSelectDialog: {
    captionDistrict: "地区",
    captionHKI: "香港岛",
    captionKLN: "九龙",
    captionNT: "新界",

    actionOK: "OK",

    selectAll: "选择全部",
  },

  langEnquiryDialog: {
    titleEnquiry: "查询",
    titleEnquirySuccess: "联络代理",

    msgProvideInfo: "请提供以下资料，我们的代理会很快与你联络。",
    msgEnquirySuccess: "多谢你的资料，代理会很快与您联络",

    captionEmail: "电邮地址",
    captionContact: "联络电话",
    captionName: "姓名",
    captionDistrict: "地区",

    actionContactMe: "联络当区代理",
    actionContactMeLeadAgents: "联络物业代理",
    actionSameDistrictAgents: "指定代理",

    errContact: "请输入正确的联络电话",
    errEmail: "请输入正确的电邮地址",
    errName: "请输入姓名",
    errDistrict: "请选择地区",
    errAtLeastTelOrEmail: "请至少输入电话或电邮",
  },
  langLogin: {
    actionLogin: "登入",
    actionLoginOrRegister: "登入/注册",
    actionForgetPassword: "忘记密码",
    actionMemberRegistration: "注册成为会员",
    msgFacebookEmailRequired: "请提供您的电邮地址以完成注册",
    msgInvalidEmail: "请输入正确的电邮地址",
    msgSocialLoginCancelled: "第三方帐户登入已取消",
    msgLoginSuccess: "登入成功!",
    msgEmailAlreadyBeenRegistered: "您的电邮已注册，请使用您的密码登入",
    msgForgetPasswordEmailRequired: "请提供您的电邮地址以完成处理",
    msgForgetPasswordRequestSuccess: "重设密码的连结已发送到你的电子邮箱",
    msgResetPasswordTokenExpiry: "重设密码请求已过时，请重新发出请求",
    msgPasswordFailComplexityRequirement: "密码必须符合设定要求",
    msgResetPasswordSuccess: "重设密码成功",
    msgPreviousPasswordUsed: "新密码不可与旧密码相同",
    msgAcceptTermsAndConditionsAndPolicy: "注册即表示你同意我们的使用条款与私隐政策。",
    titleFacebookEmail: "Facebook 登入",
    titleMemberLogin: "会员登入",
    titleResetPassword: "重设密码",

    actionLoginWithFacebook: "使用 Facebook 帐户登入",
    actionLoginWithGoogle: "使用 Google 帐户登入",
    actionLoginWithApple: "使用 Apple ID 登入",

    captionEmail: "电邮地址",
    captionPassword: "密码",
    captionRePassword: "重新输入密码",

    errEmail: "请输入正确的电邮地址",
    errInvalidEmail: "电邮地址错误",
    errInvalidToken: "认证失败",
    errPassword: "请输入密码",
    errPasswordLeadingTailingSpace: "请移除头尾空格",
    errPasswordMismatch: "请输入和上面一致的密码",

    plsRegisterOrLogin: "请登入或免费注册，以使用更多服务",
  },
  langNews: {
    titleNews: "市场动态",
    // Sharing
    msgSharingTemplate: "我在阳光物业顾问找到个楼盘新闻：%s，一齐来望下~",
    msgNoRecord: "暂无纪录",
  },
  langNotification: {
    titleNotifications: "讯息",
    actionYes: "好",
    actionNoNeed: "不用了",

    msgNoRecord: "暂无讯息",
    actionProcessed: "已处理",
  },
  langProperty: {
    actionReset: "重设",
    actionSearch: "搜寻",

    actionBuy: "买盘",
    actionRent: "租盘",

    grossAbbr: "建",
    netAbbr: "实",
    rent: "租",
    sale: "售",

    captionUnpublished: "已下架",
    metaAppUnpublishedLabelFontSize: "0.8rem",

    captionPropertyNo: "物业编号",
    captionDateModified: "更新日期",
    captionKeyword: "关键字",

    captionPrice: "价钱",
    captionRent: "租金",
    captionDistrict: "地区",
    captionPropertyUsage: "物业类型",
    captionPricePerSize: "呎价",
    captionArea: "面积",
    captionAddress: "地址",
    captionMoreOption: "更多选项",
    captionAdvancedSearch: "进阶搜寻",
    captionBuilding: "屋苑/大厦名称",
    captionStreet: "街道",
    captionRooms: "房",
    captionRoom: "房间",
    captionSuite: "套房",
    captionSuites: "套房",
    captionOpenType: "开放式",
    captionBathroom: "浴室/厕所",
    captionBalcony: "露台",
    captionGarden: "花园",
    captionHelperRoom: "工人房",
    captionRooftop: "天台",
    captionOccupancyPermitAge: "入伙期(年)",
    captionHasHelperRoom: "有",
    captionNoHelperRoom: "无",
    captionFacing: "座向",
    captionDeco: "装修",
    captionView: "景观",
    captionOtherFeatures: "特色",
    captionClubHouseFacilities: "会所设施",
    captionOthers: "其它",
    captionPrimarySchoolNet: "小学校网",
    captionSecondarySchoolNet: "中学校网",
    captionPrimarySchool: "小学",
    captionSecondarySchool: "中学",
    captionSetAsDefaultSearchCriteria: "设定为预设搜寻条件",
    captionFullTransactions: "所有成交",
    captionTransactionDate: "成交日期",

    captionPerGross: "建呎",
    captionPerNet: "实呎",
    captionYears: "年",
    captionPricePerFeet: "平均实用尺价 ($)",

    // Sorting
    captionSorting: "排序",
    captionDefaultSorting: "刊登日期",
    captionPriceRentSorting: "定价",
    captionRentSorting: "定价",
    captionAreaSorting: "面积",
    captionPriceRentPerAreaSorting: "呎价",
    captionRentPerAreaSorting: "呎租",

    captionHotpick: "精选",

    titlePropertyInfo: "物业资讯",
    titlePropertyFeatures: "物业特点",
    titlePropertyAgents: "物业代理",
    titleRecentTransactions: "近期成交",
    titleTenYearsPricePerFeet: "十年平均实用尺价",
    titlePropertySameDistrict: "同区其他楼盘",
    titlePropertySameBuilding: "同建筑物其他楼盘",
    titleBookmarkList: "心水楼盘",
    captionFeaturesOrFacilities: "特色/设施",
    captionOtherInfo: "其他资讯",
    captionSchoolNet: "校网",
    captionMorePropertyInfo: "更多物业相关资讯",
    captionOtherPropertiesForSameBuilding: "同建筑物其他楼盘",
    captionOtherPropertiesForSameDistrict: "同区其他楼盘",

    // Sharing
    msgSharingTemplate: "我在阳光物业顾问找到个笋盘：%s，一齐来看下~",

    // Sold Leased
    msgSoldClicked: "此楼盘已经出售, 请浏览其他楼盘",
    msgLeasedClicked: "此楼盘已经出租, 请浏览其他楼盘",

    // Save search
    titleSaveSearch: "储存选项",
    captionLatestSaveSearchShown: "只会显示最近的%s个储存选项",
    hintForDelSaveSearch: "向左滑动以删除",
    titleCriteriaTitle: "选项标题",
    msgPleaseEnterTitle: "请输入标题",
    msgSaveSearchSuccess: "已成功储存选项",
    msgNotExists: "该楼盘不存在或已下架",
    errPleaseEnterTitle: "请输入标题",
    captionTitle: "标题",

    actionLoadMore: "载入更多",
    actionCenterMap: "地图置中",

    msgNoRecord: "暂无纪录",
    titleNoPropertyFound: "找不到您所需的楼盘",
    msgNoPropertyFound:
      "请尝试更改搜寻的条件和地图区域。您亦可直接联络我们的代理",

    titleDisplayMap: "地图",
    titleDisplayList: "列表",
  },
  langSetting: {
    titleSettings: "设定",
    captionAbout: "关于",
    titleAboutCompany: "关于我们",
    captionCompanyMission: "公司宗旨",
    captionContactUs: "联络我们",
    captionLanguage: "语言",
    captionMessages: "讯息",
    captionPushNotiEnabled: "推送服务",
    captionTermsAndConditions: "使用条款",
    captionPrivacy: "私隐政策",
    captionQA: "常见问题",
    captionVersion: "版本",
  },
};

export default zhCN;